import React from 'react';
import './App.css';

const logoUrl = "https://sa1workflow1shared1eus1.blob.core.windows.net/rli-workflow-public-prod/email-assests/images/risklogic-logo.webp"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://risklogic.com"
          rel="noopener noreferrer"
        >
          <img src={logoUrl} className="App-logo" alt="logo" />
        </a>
      </header>
    </div>
  );
}

export default App;
